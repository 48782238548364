// src/plugins/posthog.js
import posthog from 'posthog-js'

export default {
  install(Vue) {
    posthog.init(
      'phc_qqrWGxCJU8rGdaoYD3ecYf3Qncpx3ZHRR7qcXEKx3hg',
      {
        api_host: 'https://us.i.posthog.com',
        capture_pageview: false,
      }
    )

    Vue.prototype.$posthog = posthog
  }
}
