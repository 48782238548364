<template>
    <div id="blog">
        <!-- Preloader Start -->
        <div class="preloader">
            <div class="loading-container">
                <div class="loading"></div>
                <div id="loading-icon"><img
                        src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/Ingomu-Logo-White.png" alt="">
                </div>
            </div>
        </div>
        <!-- Preloader End -->

        <!-- Header Start -->
        <SiteHeader></SiteHeader>
        <!-- Header End -->

        <!-- Page Header Start -->
        <div class="page-header bg-section parallaxie">
            <!-- Page Header Box Start -->
            <div class="page-header-box">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <!-- Page Header Content Start -->
                            <div class="page-header-content">
                                <h1 class="wow fadeInUp">Latest Updates &<br><span>Coaching Insights</span></h1>
                                <nav class="wow fadeInUp" data-wow-delay="0.25s">
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><router-link :to="{ name: 'home'}">home</router-link></li>
                                        <li class="breadcrumb-item active" aria-current="page">blog</li>
                                    </ol>
                                </nav>
                            </div>
                            <!-- Page Header Content End -->
                        </div>
                    </div>
                </div>
            </div>
            <!-- Page Header Box End -->
        </div>
        <!-- Page Header End -->

        <!-- Page Blog Start -->
        <div class="page-blog" v-if="shownArray.length > 0">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-6" v-for="blog in shownArray" :key="blog.slug">
                        <!-- Post Item Start -->
                        <div class="post-item wow fadeInUp">
                            <!-- Post Featured Image Start -->
                            <div class="post-featured-image">
                                <figure>
                                    <router-link :to="{ name: 'blogdetail', params: { slug: blog.slug }, }" class="image-anime">
                                        <img :src="imageLink(blog.xlimage)" alt="">
                                    </router-link>
                                </figure>

                                <div class="post-btn">
                                    <router-link :to="{ name: 'blogdetail', params: { slug: blog.slug }, }"><img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/arrow-white.svg" alt=""></router-link>
                                </div>
                            </div>
                            <!-- Post Featured Image End -->

                            <!-- Post Item Content Start -->
                            <div class="post-item-content">
                                <h2><router-link :to="{ name: 'blogdetail', params: { slug: blog.slug }, }">{{ blog.title }}</router-link>
                                </h2>
                                <p>{{ strippedHtml(blog.body) }}...</p>
                            </div>
                            <!-- Post Item Content End -->
                        </div>
                        <!-- Post Item End -->
                    </div>
                    <div class="col-lg-12">
                    <!-- Page Pagination Start -->
                    <div class="page-pagination wow fadeInUp" data-wow-delay="1.2s">
                        <ul class="pagination">
                            <li v-if="this.currentPage > 1"><router-link :to="{ name: 'blogpage', params: { page: this.currentPage - 1} }"><i class="fa-solid fa-arrow-left-long"></i></router-link></li>
                            <li v-if="this.currentPage <= this.numPages" class="active"><router-link :to="{ name: 'blogpage', params: { page: this.currentPage} }">{{this.currentPage}}</router-link></li>
                            <li v-if="this.currentPage+1 <= this.numPages"><router-link :to="{ name: 'blogpage', params: { page: this.currentPage+1} }">{{this.currentPage+1}}</router-link></li>
                            <li v-if="this.currentPage+2 <= this.numPages"><router-link :to="{ name: 'blogpage', params: { page: this.currentPage+3} }">{{this.currentPage+2}}</router-link></li>
                            <li v-if="this.currentPage < this.numPages"><router-link :to="{ name: 'blogpage', params: { page: this.currentPage + 1} }"><i class="fa-solid fa-arrow-right-long"></i></router-link></li>
                        </ul>
                    </div>
                    <!-- Page Pagination End -->
                </div>
                </div>
            </div>
        </div>
        <!-- Page Blog End -->

        <!-- Footer Start -->
        <SiteFooter></SiteFooter>
        <!-- Footer End -->
    </div>
</template>
<script>
import SiteHeader from "@/components/InfineHeader";
import SiteFooter from "@/components/InfineFooter";
import $ from "jquery";
import { slicknav } from '../../../mixins/slicknav';
import { parrallaxie } from "../../../mixins/parallaxie";
import { mapActions, mapGetters } from "vuex";
import { reveal } from "../../../mixins/reveal";
import posthog from 'posthog-js';

export default {
    name: "blogv4",
    metaInfo() {
		return {
			title: "Latest Updates & Coaching Insights | Ingomu App",
		}
	},
    components: {
        SiteHeader,
        SiteFooter
    },
    data() {
        return {
            blogcount: 0,
            currentPage: 1,
            contentPerPage: 6,
            numPages: 0,
            postArray: [],
            shownArray: []
        }
    },
    mounted() {
        this.currentPage = parseInt(this.$route.params.page) || 1
        this.$store.dispatch("resetBlogList");
        this.$store.dispatch("getBlogList");

        if (this.$route.hash) {
            setTimeout(() => this.scrollTo(this.$route.hash), TIMEOUT);
        }
        posthog.capture('$pageview')

        var $window = $(window);

        // /* Preloader Effect */
        // $window.on('load', function () {
        //     $(".preloader").fadeOut(600);
        // });

        $(function () {
            /* Preloader Effect */
            setTimeout(function () {
                $(".preloader").fadeOut(600);
            }, 600);

            /* Animated Wow Js */
            new WOW().init();

            // Parallax
            parrallaxie()

            // Reveal
            reveal()

            /* Slick Menu JS */
            slicknav()
        })
    },
    beforeDestroy() {
        posthog.capture('$pageleave')
    },
    methods: {
        trimBlogList() {
            if (this.postArray.length > 0) {
                let start = this.contentPerPage * (this.currentPage - 1)
                let end = this.currentPage * this.contentPerPage
                if (start < this.postArray.length) {
                this.shownArray = this.postArray.slice(start, end)
                } else {
                    this.shownArray = this.postArray.slice(0, this.contentPerPage)
                }
            } else {
                this.shownArray = []
            }
        },
        strippedHtml(htmlText) {
            return htmlText.replace(/<[^>]+>/g, '').split(' ').slice(0, 25).join(" ")
        },
        imageLink(url) {
            // return unescape(url.substring(0, url.indexOf("&token=")))
            return unescape(url.substring(0, url.indexOf("&token=")))
        }
    },
    computed: {
        ...mapGetters(["blogList"]),
    },
    watch: {
        $route(to, from) {
            this.currentPage = parseInt(to.params.page) || 1
            this.trimBlogList()
        },
        blogList(newValue) {
            if (newValue != null) {
                this.numPages = Math.ceil(newValue.length / this.contentPerPage)
                this.blogcount = newValue.length
                this.postArray = newValue
                this.trimBlogList()
            } else {
                this.postArray = []
                this.shownArray = []
                this.numPages = 0
            }
        }
    }
}
</script>
<style scoped>
.page-header {
    background: url(https://ingomulibrary.s3.us-east-1.amazonaws.com/images/ingomu-blog.png?v1);
}

.post-featured-image figure a {
    cursor: pointer;
}

@media (max-width:480px) {
    .page-header {
        background: url(https://ingomulibrary.s3.us-east-1.amazonaws.com/images/ingomu-blog-mobile.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
}
</style>