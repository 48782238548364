<template>
    <div id="speaking">
        <!-- Preloader Start -->
        <div class="preloader">
            <div class="loading-container">
                <div class="loading"></div>
                <div id="loading-icon"><img
                        src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/Ingomu-Logo-White.png" alt="">
                </div>
            </div>
        </div>
        <!-- Preloader End -->

        <!-- Header Start -->
        <SiteHeader></SiteHeader>
        <!-- Header End -->

        <!-- Page Header Start -->
        <div class="page-header bg-section parallaxie">
            <!-- Page Header Box Start -->
            <div class="page-header-box">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <!-- Page Header Content Start -->
                            <div class="page-header-content">
                                <h1 class="wow fadeInUp">Connecting You<br><span>With Inspiring Voices</span></h1>
                            </div>
                            <!-- Page Header Content End -->
                        </div>
                    </div>
                </div>
            </div>
            <!-- Page Header Box End -->
        </div>
        <!-- Page Header End -->

        <!-- Company Growth Section Start -->
        <div class="company-growth" id="about">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-5">
                        <!-- Company Growth Image Start -->
                        <div class="company-growth-image">
                            <figure class="image-anime reveal">
                                <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/ingomu-speakers-theresa-byrne-2.png"
                                    alt="">
                            </figure>
                        </div>
                        <!-- Company Growth Image End -->
                    </div>

                    <div class="col-lg-7">
                        <!-- Company Growth Content Start -->
                        <div class="company-growth-content">
                            <!-- Section Title Start -->
                            <div class="section-title">
                                <h3 class="wow fadeInUp">ABOUT</h3>
                                <h2 class="wow fadeInUp" data-wow-delay="0.25s">Elevate Your Team and
                                    Events<br><span>with Expert High-Level Speakers and Workshops</span></h2>
                                <p class="wow fadeInUp mt-16" data-wow-delay="0.5s">Are you a meeting professional? Or a
                                    learning and development manager juggling an enormous amount of details bringing in
                                    speakers to your event or organization? <i>Let us help!</i>
                                </p>
                                <p class="wow fadeInUp mt-16" data-wow-delay="0.5s">Our concierge-style speaker bureau
                                    is a trusted partner in securing high-level keynote speakers, facilitators, and
                                    workshop leaders designed to elevate your events.</p>
                                <p class="wow fadeInUp mt-16" data-wow-delay="0.5s"><b><i>In person?</i></b> We’ve got
                                    you. <b><i>Virtual?</i></b> We take care of that too!</p>
                                <p class="wow fadeInUp mt-16" data-wow-delay="0.5s">From inspiring keynotes to engaging,
                                    interactive workshops, we deliver a seamless, full-service experience tailored to
                                    your event’s unique goals. Let us handle the details so you can focus on what really
                                    matters—creating connections, sparking inspiration, and enjoying the event alongside
                                    your attendees.</p>
                                <p class="wow fadeInUp mt-16" data-wow-delay="0.5s"><b><i>Relax, have fun, and leave the
                                            logistics to us!</i></b></p>
                            </div>
                            <!-- Section Title End -->

                            <div class="col-lg-12 col-md-12 row">
                                <div class="col-md-4 col-6 mb-3">
                                    <!-- Company Growth Button Start -->
                                    <div class="company-growth-btn wow fadeInUp" data-wow-delay="0.75s">
                                        <a href="https://calendar.app.google/CUtZn21V8aq4A5fV8" target="_blank"
                                            class="btn-default textnone">Book a Call Today</a>
                                    </div>
                                    <!-- Company Growth Button End -->
                                </div>

                                <div class="col-md-4 mb-3 thirditem">
                                    <div class="intro-video-box">
                                        <div class="video-play-button wow fadeInUp" data-wow-delay="0.75s">
                                            <a href="https://www.youtube.com/watch?v=pa0ueIpFSrE" class="popup-video"
                                                data-cursor-text="Play">
                                                <i class="fa-solid fa-play"></i>
                                            </a>
                                            <p class="text-black">Experience It</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Company Growth Content End -->
                    </div>
                </div>
            </div>
        </div>
        <!-- Company Growth Section End -->

        <!-- Our Service Start -->
        <div class="our-service bg-section mb-60" id="service">
            <div class="container">
                <div class="row">
                    <div class="col-lg-5">
                        <!-- Service Content Start -->
                        <div class="service-content">
                            <!-- Section Title Start -->
                            <div class="section-title">
                                <h3 class="wow fadeInUp">SERVICE</h3>
                                <h2 class="wow fadeInUp" data-wow-delay="0.25s">More Than Speakers<br><span>We Deliver
                                        Results</span></h2>
                                <p class="wow fadeInUp mt-16" data-wow-delay="0.5s">We don’t just provide speakers, we
                                    purposefully deliver memorable experiences. Our speakers bring energy, relevance,
                                    and actionable takeaways that turn your event into a transformative and engaging
                                    experience for your audience.
                                </p>
                                <p class="wow fadeInUp mt-16" data-wow-delay="0.5s">Let us take the guesswork out of
                                    hiring the perfect speaker or facilitator. With our concierge service, you have a
                                    trusted partner who ensures your event runs smoothly and successfully.
                                </p>
                                <p class="wow fadeInUp mt-16" data-wow-delay="0.5s">Let’s Elevate Your Next Conference
                                    or Meeting—Together.</p>
                            </div>
                            <!-- Section Title End -->

                            <!-- Service Btn Start -->
                            <div class="service-btn wow fadeInUp" data-wow-delay="0.75s">
                                <a href="https://calendar.app.google/CUtZn21V8aq4A5fV8" target="_blank"
                                    class="btn-default textnone">Book a Call Today</a>
                            </div>
                            <!-- Service Btn End -->
                        </div>
                        <!-- Service Content End -->
                    </div>

                    <div class="col-lg-7">
                        <!-- Service Item List Start -->
                        <div class="service-item-list">
                            <!-- Service Item Start -->
                            <div class="service-item wow fadeInUp">
                                <div class="icon-box">
                                    <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/icon-our-mission.svg"
                                        alt="">
                                </div>

                                <div class="service-item-content">
                                    <h3>Tailored Expertise</h3>
                                    <p>We curate a diverse selection of speakers, perfectly aligned with your event’s
                                        theme, industry, and audience. Whether you’re looking for cutting-edge insights
                                        or inspirational stories, we ensure the content resonates and engages with your
                                        audience.</p>
                                </div>
                            </div>
                            <!-- Service Item Start -->

                            <!-- Service Item Start -->
                            <div class="service-item wow fadeInUp" data-wow-delay="0.2s">
                                <div class="icon-box">
                                    <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/icon-our-mission.svg"
                                        alt="">
                                </div>

                                <div class="service-item-content">
                                    <h3>Stress-Free Logistics</h3>
                                    <p>From negotiating fees to managing travel and tech requirements, we handle all the
                                        logistics so you can focus on what matters most—creating an unforgettable event.
                                    </p>
                                </div>
                            </div>
                            <!-- Service Item Start -->

                            <!-- Service Item Start -->
                            <div class="service-item wow fadeInUp" data-wow-delay="0.4s">
                                <div class="icon-box">
                                    <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/icon-our-mission.svg"
                                        alt="">
                                </div>

                                <div class="service-item-content">
                                    <h3>Custom Content</h3>
                                    <p>Our speakers don't just show up, they deliver based on your unique needs. We work
                                        closely with them to craft customized presentations, ensuring the message aligns
                                        with your goals and leaves a lasting impact on your attendees.</p>
                                </div>
                            </div>
                            <!-- Service Item Start -->

                            <!-- Service Item Start -->
                            <div class="service-item wow fadeInUp" data-wow-delay="0.6s">
                                <div class="icon-box">
                                    <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/icon-our-mission.svg"
                                        alt="">
                                </div>

                                <div class="service-item-content">
                                    <h3>On-Demand Support</h3>
                                    <p>We offer hands-on support every step of the way, including day-of coordination
                                        and real-time problem solving. Need a replacement speaker at the last minute?
                                        <i>We’ve got you covered.</i>
                                    </p>
                                </div>
                            </div>
                            <!-- Service Item Start -->

                            <!-- Service Item Start -->
                            <div class="service-item wow fadeInUp" data-wow-delay="0.8s">
                                <div class="icon-box">
                                    <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/icon-our-mission.svg"
                                        alt="">
                                </div>

                                <div class="service-item-content">
                                    <h3>For Every Format</h3>
                                    <p>From in-person summit to a virtual conference, we offer experts who can adapt to
                                        any platform and setting, ensuring your event shines – no matter the format.</p>
                                </div>
                            </div>
                            <!-- Service Item Start -->

                            <!-- Service Item Start -->
                            <div class="service-item wow fadeInUp" data-wow-delay="1s">
                                <div class="icon-box">
                                    <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/icon-our-mission.svg"
                                        alt="">
                                </div>

                                <div class="service-item-content">
                                    <h3>Proven Results</h3>
                                    <p>Our speakers don’t just inform—they transform. With measurable outcomes and
                                        audience feedback as a priority, we ensure every speaker leaves your attendees
                                        inspired, motivated, and equipped with actionable insights.</p>
                                </div>
                            </div>
                            <!-- Service Item Start -->
                        </div>
                        <!-- Service Item List End -->

                        <!-- Service Footer Start -->
                        <div class="service-footer wow fadeInUp" data-wow-delay="0.25s">
                            <p><span>Free</span> <router-link :to="{ name: 'speaking', hash: '#contact-us' }"
                                    @click.native="scrollTo('#contact-us')" class="textnone">Send us
                                    a note</router-link> to
                                request your free quote.</p>
                        </div>
                        <!-- Service Footer Start -->
                    </div>
                </div>
            </div>
        </div>
        <!-- Our Service End -->

        <!-- Our Approach Start -->
        <div class="our-approach bg-section" id="howwework">
            <div class="container">
                <div class="row section-row align-items-center">
                    <div class="col-lg-7">
                        <!-- Section Title Start -->
                        <div class="section-title">
                            <h3 class="wow fadeInUp">How We Work</h3>
                            <h2 class="wow fadeInUp" data-wow-delay="0.25s">Empowering Growth
                                Through<br><span>Personalized Guidance
                                </span></h2>
                        </div>
                        <!-- Section Title End -->
                    </div>

                    <div class="col-lg-5">
                        <!-- Section Btn Start -->
                        <div class="section-btn wow fadeInUp" data-wow-delay="0.5s">
                            <router-link :to="{ name: 'speaking', hash: '#contact-us' }"
                                @click.native="scrollTo('#contact-us')" class="btn-default textnone">contact
                                us</router-link>
                        </div>
                        <!-- Section Btn End -->
                    </div>
                </div>

                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <!-- Our Approach Content Start -->
                        <div class="our-approach-content">
                            <p class="wow fadeInUp">At Ingomu Speaking, we make booking the right speaker for your event
                                easy and stress-free. Simply tell us about your event’s needs, and we’ll provide
                                personalized speaker recommendations tailored to your goals. Once you’ve found the
                                perfect match, we’ll provide concierge services to handle all the details, ensuring a
                                seamless experience from start to finish.</p>

                            <!-- Mission Vision Item Start -->
                            <div class="mission-vision-list">
                                <div class="row">
                                    <div class="col-md-6 col-12">
                                        <!-- Mission Vision Item Start -->
                                        <div class="mission-vision-item wow fadeInUp" data-wow-delay="0.2s">
                                            <div class="icon-box">
                                                <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/icon-our-mission.svg"
                                                    alt="">
                                            </div>

                                            <div class="mission-vision-content">
                                                <h3>Tell Us About Your Event</h3>
                                                <p>Using our proprietary Speaker Match Survey we take the time to
                                                    understand your event's objectives and audience, providing
                                                    personalized speaker recommendations that align with your specific
                                                    needs.</p>
                                            </div>
                                        </div>
                                        <!-- Mission Vision Item End -->
                                    </div>

                                    <div class="col-md-6 col-12">
                                        <!-- Mission Vision Item Start -->
                                        <div class="mission-vision-item wow fadeInUp" data-wow-delay="0.4s">
                                            <div class="icon-box">
                                                <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/icon-our-mission.svg"
                                                    alt="">
                                            </div>

                                            <div class="mission-vision-content">
                                                <h3>Get Personalized Speaker Recommendations</h3>
                                                <p>Based on your event’s specifics, we provide a curated list of
                                                    speakers who align with your objectives and audience.</p>
                                            </div>
                                        </div>
                                        <!-- Mission Vision Item End -->
                                    </div>

                                    <div class="col-lg-12 col-12">
                                        <!-- Our Value Item Start -->
                                        <div class="mission-vision-item our-value-item wow fadeInUp"
                                            data-wow-delay="0.6s">
                                            <div class="icon-box">
                                                <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/icon-our-mission.svg"
                                                    alt="">
                                            </div>

                                            <div class="mission-vision-content">
                                                <h3>Book the Perfect Match</h3>
                                                <p>Once you’ve selected your speaker, we’ll take care of the logistics,
                                                    ensuring a smooth and seamless experience from booking to event day.
                                                </p>
                                            </div>
                                        </div>
                                        <!-- Our Value Item End -->
                                    </div>
                                </div>
                            </div>
                            <!-- Mission Vision Item End -->

                            <!-- Our Approch Content Footer Start -->
                            <div class="our-approach-content-footer wow fadeInUp" data-wow-delay="0.8s">
                                <a href="https://calendar.app.google/CUtZn21V8aq4A5fV8" target="_blank"
                                    class="btn-default">Find Your Perfect Speaker Today. <u>Let's Chat!</u> </a>
                            </div>
                            <!-- Our Approch Content Footer End -->
                        </div>
                        <!-- Our Approach Content End -->
                    </div>

                    <div class="col-lg-6">
                        <!-- Our Approach Image Start -->
                        <div class="our-approach-image">
                            <figure class="image-anime reveal">
                                <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/ingomu-speakers-how-we-work.png"
                                    alt="">
                            </figure>
                        </div>
                        <!-- Our Approach Image End -->
                    </div>
                </div>
            </div>
        </div>
        <!-- Our Approach End -->

        <!-- Our FAQs Section Start -->
        <div class="our-faqs bg-section mt-100" id="faq">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <!-- Our FAQs Content Start -->
                        <div class="our-faqs-content">
                            <!-- Section Title Start -->
                            <div class="section-title">
                                <h3 class="wow fadeInUp">FAQ</h3>
                                <h2 class="wow fadeInUp" data-wow-delay="0.25s">Answers to<br><span>Your
                                        Questions</span></h2>
                            </div>
                            <!-- Section Title End -->

                            <!-- FAQs CTA Box Start -->
                            <div class="faqs-cta-box wow fadeInUp" data-wow-delay="0.75s">
                                <div class="faqs-cta-content">
                                    <h3>Do you still have questions?</h3>
                                    <p>We're ready to help!</p>
                                    <router-link :to="{ name: 'speaking', hash: '#contact-us' }"
                                        @click.native="scrollTo('#contact-us')" class="btn-default mr-20">Contact
                                        Us</router-link>
                                    <a href="https://calendar.app.google/DxVRDNezM5idW1p1A"
                                        class="btn-default textnone">Book a Call Today</a>
                                </div>
                            </div>
                            <!-- FAQs CTA Box End -->
                        </div>
                        <!-- Our FAQs Content End -->
                    </div>

                    <div class="col-lg-6">
                        <div class="our-faq-section">
                            <!-- FAQ Accordion Start -->
                            <div class="faq-accordion" id="faqaccordion">
                                <!-- FAQ Item Start -->
                                <div class="accordion-item wow fadeInUp" v-for="(faq, index) in faqGeneral"
                                    :key="index">
                                    <h2 class="accordion-header" :id="faq.id">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" :data-bs-target="faq.hash" aria-expanded="true"
                                            :aria-controls="faq.controls">
                                            {{ faq.question }}
                                        </button>
                                    </h2>
                                    <div :id="faq.controls" class="accordion-collapse collapse"
                                        :aria-labelledby="faq.id" data-bs-parent="#faqaccordion">
                                        <div class="accordion-body">
                                            <p v-html="faq.answer"></p>
                                        </div>
                                    </div>
                                </div>
                                <!-- FAQ Item End -->
                            </div>
                            <!-- FAQ Accordion End -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Our FAQs Section End -->

        <!-- Page Contact Us Start -->
        <div class="page-contact-us bg-section mb-60" id="contact-us">
            <div class="container">
                <div class="row section-row align-items-center">
                    <div class="col-lg-7 col-md-9">
                        <!-- Section Title Start -->
                        <div class="section-title">
                            <h3 class="wow fadeInUp">Contact Us</h3>
                            <h2 class="wow fadeInUp" data-wow-delay="0.25s">Let's Talk</h2>
                        </div>
                        <!-- Section Title End -->
                    </div>
                </div>

                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <!-- Contact Us Form Start -->
                        <div class="contact-us-form">
                            <!-- Contact Us Title Start -->
                            <div class="contact-us-title">
                                <h3 class="wow fadeInUp textnone">Send a Message</h3>
                                <p class="wow fadeInUp" data-wow-delay="0.2s">
                                    By submitting this form, you consent to be contacted at the email address provided.
                                    You may opt out of communications at any time by following the instructions included
                                    in our emails. Your information will be kept strictly confidential and will not be
                                    shared, sold, or used for purposes other than those stated. For more details, please
                                    refer to our <router-link :to="{ name: 'terms' }">Privacy Policy</router-link>.
                                </p>
                            </div>
                            <!-- Contact Us Title End -->

                            <!-- Contact Us Form Start -->
                            <form id="contactForm" action="#" method="POST" data-toggle="validator" class="wow fadeInUp"
                                data-wow-delay="0.4s">
                                <div class="row">
                                    <div class="form-group col-lg-12 mb-4">
                                        <input type="text" name="name" class="form-control" id="name" placeholder="Name"
                                            required>
                                        <div class="help-block with-errors"></div>
                                    </div>

                                    <div class="form-group col-lg-12 mb-4">
                                        <input type="email" name="email" class="form-control" id="email"
                                            placeholder="Email Address" required>
                                        <div class="help-block with-errors"></div>
                                    </div>

                                    <div class="form-group col-md-12 mb-5">
                                        <textarea name="message" class="form-control" id="message" rows="4"
                                            placeholder="Please Share Your Speaker or Workshop Needs with Us."
                                            required></textarea>
                                        <div class="help-block with-errors"></div>
                                    </div>

                                    <div class="col-lg-12">
                                        <div class="contact-form-btn">
                                            <button type="submit" class="btn-default mb-25">submit message</button>
                                            <div id="msgSubmit" class="h3 hidden"></div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <!-- Contact Us Form End -->
                        </div>
                        <!-- Contact Us Form End -->
                    </div>

                    <div class="col-lg-6">
                        <!-- Contact Information Start -->
                        <div class="contact-information">
                            <!-- Contact Info Item Start -->
                            <div class="contact-info-item wow fadeInUp">

                                <!-- Footer Contact Information Start -->
                                <div class="contact-info-contant">
                                    <h3>Connect With Us</h3>
                                </div>
                                <!-- Footer Contact Information End -->

                                <!-- Footer Social Link Start -->
                                <!-- Post Social Links Start -->
                                <div class="post-social-sharing wow fadeInUp" data-wow-delay="0.5s">
                                    <ul>
                                        <li><a href="https://www.linkedin.com/company/ingomu" target="_blank"><i
                                                    class="fa-brands fa-linkedin-in"></i></a></li>
                                        <li><a href="https://www.instagram.com/ingomu.coaching/" target="_blank"><i
                                                    class="fa-brands fa-instagram"></i></a></li>
                                        <li><a href="https://www.youtube.com/@ingomu" target="_blank"><i
                                                    class="fa-brands fa-youtube"></i></a></li>
                                        <li><a href="https://www.facebook.com/ingomu.coaching/" target="_blank"><i
                                                    class="fa-brands fa-facebook-f"></i></a></li>
                                        <li><a href="https://www.pinterest.com/ingomu_coaching/"><i
                                                    class="fa-brands fa-pinterest"></i></a></li>
                                    </ul>
                                </div>
                                <!-- Post Social Links End -->
                                <!-- Footer Social Link End -->
                            </div>
                            <!-- Contact Info Item End -->

                            <!-- Contact Info Item Start -->
                            <div class="contact-info-item wow fadeInUp" data-wow-delay="0.25s">
                                <!-- Contact Info Contact Start -->
                                <div class="contact-info-contant">
                                    <a href="https://calendar.app.google/DxVRDNezM5idW1p1A" target="_blank"
                                        class="btn-default textnone">Book a Call Today</a>
                                </div>
                                <!-- Contact Info Contact End -->

                            </div>
                            <!-- Contact Info Item End -->

                        </div>
                        <!-- Contact Information End -->
                    </div>
                </div>
            </div>
        </div>
        <!-- Page Contact Us End -->

        <!-- Speakin modal Start -->
        <Speakin />
        <!-- Speakin modal End -->

        <!-- Footer Start -->
        <footer class="main-footer bg-section">
            <div class="container">
                <div class="row">
                    <div class="col-lg-7 col-md-6">
                        <!-- About Footer Start -->
                        <div class="about-footer">
                            <!-- Section Title Start -->
                            <div class="section-title">
                                <h2 class="fs-35">What you do today<br><span>can improve all your tomorrows.</span></h2>
                                <p class="text-white">- Ralph Martson</p>
                            </div>
                            <!-- Section Title End -->
                        </div>
                        <!-- About Footer End -->
                    </div>

                    <div class="col-lg-3 col-md-3">
                        <!-- Footer Contact Information Start -->
                        <div class="footer-contact-info">
                            <h3>Connect With Us</h3>
                        </div>
                        <!-- Footer Contact Information End -->

                        <!-- Footer Social Link Start -->
                        <div class="footer-social-links">
                            <ul>
                                <li><a href="https://www.linkedin.com/company/ingomu" target="_blank"><i
                                            class="fa-brands fa-linkedin" target="_blank"></i></a></li>
                                <li><a href="https://www.instagram.com/ingomu.coaching/" target="_blank"><i
                                            class="fa-brands fa-instagram"></i></a></li>
                                <li><a href="https://www.youtube.com/@ingomu" target="_blank"><i
                                            class="fa-brands fa-youtube" target="_blank"></i></a></li>
                                <li><a href="https://www.facebook.com/ingomu.coaching/" target="_blank"><i
                                            class="fa-brands fa-facebook-f"></i></a></li>
                                <li><a href="https://www.pinterest.com/ingomu_coaching/" target="_blank"><i
                                            class="fa-brands fa-pinterest-p"></i></a></li>
                            </ul>
                        </div>
                        <!-- Footer Social Link End -->
                    </div>

                    <div class="col-lg-2 col-md-3">
                        <!-- Footer Contact Information Start -->
                        <div class="footer-contact-info">
                            <h3>say hello</h3>
                            <p><a href="mailto:contact@ingomu.com?subject=Website%20Email%20Message"
                                    class="text-white">Contact@Ingomu.com</a></p>
                        </div>
                        <!-- Footer Contact Information End -->
                    </div>
                </div>

                <!-- Footer Copyright Section Start -->
                <div class="footer-copyright">
                    <div class="row align-items-center">
                        <div class="col-md-6">
                            <!-- Footer Menu Start -->
                            <div class="footer-menu">
                                <ul>
                                    <li><router-link :to="{ name: 'home', hash: '#home' }"
                                            @click.native="scrollTo('home')">Home</router-link></li>
                                    <li><router-link
                                            :to="{ name: 'blogtags', params: { tag: 'Career opportunities' } }">Careers</router-link>
                                    </li>
                                    <li><router-link :to="{ name: 'terms' }">Terms & Privacy</router-link></li>
                                    <li><router-link :to="{ name: 'speaking', hash: '#contact-us' }"
                                            @click.native="scrollTo('#contact-us')">Contact</router-link></li>
                                </ul>
                            </div>
                            <!-- Footer Menu End -->
                        </div>

                        <div class="col-md-6">
                            <!-- Footer Copyright Start -->
                            <div class="footer-copyright-text">
                                <p>Copyright © {{ currentDate.getFullYear() }} Ingomu Learning LLC. All Rights Reserved.
                                </p>
                            </div>
                            <!-- Footer Copyright End -->
                        </div>
                    </div>
                </div>
                <!-- Footer Copyright Section End -->
            </div>
        </footer>
        <!-- Footer End -->
    </div>
</template>
<script>
import SiteHeader from "@/components/SpeakingHeader";
import $ from "jquery";
import { slicknav } from '../../../mixins/slicknav';
import { parrallaxie } from "../../../mixins/parallaxie";
import { mapActions, mapGetters } from "vuex";
import { reveal } from "../../../mixins/reveal";
import { validator } from "../../../mixins/validator";
import Speakin from "../../../components/Speakin.vue";
import postscribe from "postscribe";
import Swal from "sweetalert2";
import posthog from 'posthog-js';

const TIMEOUT = 1000;

export default {
    name: "speaking",
    metaInfo() {
        return {
            title: "Connecting you with Inspiring Voices",
        }
    },
    components: {
        SiteHeader,
        Speakin
    },
    data() {
        return {
            currentDate: new Date,
            faqGeneral: [
                {
                    id: "headingOne",
                    controls: "collapseOne",
                    hash: "#collapseOne",
                    question: "What types of speakers do you offer?",
                    answer: "We offer a diverse range of speakers, including industry experts, keynote speakers, workshop facilitators, and panelists across a variety of fields."
                },
                {
                    id: "headingTwo",
                    controls: "collapseTwo",
                    hash: "#collapseTwo",
                    question: "How do you match speakers with my event's theme and goals?",
                    answer: "Using our proprietary Speaker Match Survey we take the time to understand your event's objectives and audience, providing personalized speaker recommendations that align with your specific needs."
                },
                {
                    id: "headingThree",
                    controls: "collapseThree",
                    hash: "#collapseThree",
                    question: "Can you accommodate both in-person and virtual events?",
                    answer: "Yes, we work with speakers who are experienced in delivering content for both in-person and virtual events, ensuring a smooth experience regardless of format."
                },
                {
                    id: "headingFour",
                    controls: "collapseFour",
                    hash: "#collapseFour",
                    question: "What is the cost of booking a speaker through your bureau?",
                    answer: "Speaker fees vary depending on the speaker's experience, event type, and location. We provide transparent pricing once we understand your specific requirements."
                },
                {
                    id: "headingFive",
                    controls: "collapseFive",
                    hash: "#collapseFive",
                    question: "How far in advance should I book a speaker for my event?",
                    answer: "Ideally, we recommend booking a speaker at least 4-6 months before your event to ensure availability, but we can often accommodate last-minute requests."
                },
                {
                    id: "headingSix",
                    controls: "collapseSix",
                    hash: "#collapseSix",
                    question: "What are the speaker's travel and accommodation requirements?",
                    answer: "Travel and accommodation arrangements are typically handled by our bureau, with costs outlined upfront based on the speaker's location and event details."
                },
                {
                    id: "headingSeven",
                    controls: "collapseSeven",
                    hash: "#collapseSeven",
                    question: "Do your speakers customize their presentations?",
                    answer: "Yes, our speakers work closely with you to tailor their presentations to fit your event’s goals and audience, ensuring relevant and impactful content."
                },
                {
                    id: "headingEight",
                    controls: "collapseEight",
                    hash: "#collapseEight",
                    question: "How do you ensure a seamless event experience from start to finish?",
                    answer: "We provide end-to-end support, from selecting the right speaker to handling logistics and ensuring the speaker's needs are met, allowing you to focus on your event."
                },
                {
                    id: "headingNine",
                    controls: "collapseNine",
                    hash: "#collapseNine",
                    question: "What happens if a speaker is unavailable or has an emergency?",
                    answer: "We have a backup plan in place, offering alternative speakers or assistance in finding a suitable replacement to ensure your event proceeds smoothly."
                }
            ]
        }
    },
    methods: {
        ...mapActions(["contactUs"]),
        submit(name, email, message) {
            var _this = this;
            _this.contactUs({
                name: name,
                email: email,
                message: message,
                page: "Speaker"
            })
        },
        strippedHtml(htmlText) {
            return htmlText.replace(/<[^>]+>/g, '').split(' ').slice(0, 3).join(" ")
        },
        scrollTo(hashtag) {
            document.getElementById(hashtag.slice(1)).scrollIntoView()
        },
    },
    mounted() {
        if (this.$route.hash) {
            setTimeout(() => this.scrollTo(this.$route.hash), TIMEOUT);
        }
        // postscribe(
        //     "#speaking",
        //     `<script type="text/javascript">
        //         var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
        //         (function(){
        //         var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
        //         s1.async=true;
        //         s1.src='https://embed.tawk.to/5c8934e5c37db86fcfcd99d4/default';
        //         s1.charset='UTF-8';
        //         s1.setAttribute('crossorigin','*');
        //         s0.parentNode.insertBefore(s1,s0);
        //         })();
        //         <\/script>`
        // );
        posthog.capture('$pageview')

        var $window = $(window);
        var $this = this;

        $(function () {
            /* Preloader Effect */
            setTimeout(function () {
                $(".preloader").fadeOut(600);
            }, 600);

            $('.popup-video').magnificPopup({
                type: 'iframe',
                mainClass: 'mfp-fade',
                removalDelay: 160,
                preloader: false,
                fixedContentPos: true
            });

            /* Animated Wow Js */
            new WOW().init();

            // Parallax
            parrallaxie()

            // Reveal
            reveal()

            /* Slick Menu JS */
            slicknav()

            validator((contactForm) => {
                let name = contactForm[0]["value"];
                let email = contactForm[1]["value"];
                let message = contactForm[2]["value"];
                posthog.capture('contactus_speaker', {
                    'date': new Date().toString(),
                    'email': this.email,
                    'name': this.name
                });
                $this.submit(name, email, message);
                Swal.fire({
                    title: "",
                    text: "Thank You. Someone will be in contact with you shortly.",
                    icon: "success",
                    confirmButtonClass: "btn btn-secondary",
                    heightAuto: true,
                });
            })
        })
    },
    beforeDestroy() {
        posthog.capture('$pageleave')
    },
}
</script>
<style scoped>
.textnone {
    text-transform: none;
}

.page-header {
    background: url(https://ingomulibrary.s3.us-east-1.amazonaws.com/images/ingomu-speakers-and-workshops.png);
}

.mr-20 {
    margin-right: 20px;
}

.company-growth {
    background: none;
}

.mb-60 {
    margin-bottom: 60px;
}

.mb-25 {
    margin-bottom: 25px;
}

.company-growth-content .section-title h2 {
    max-width: none;
}

.page-book-appointment {
    background: var(--white-color);
    padding: 100px 0;
}

.mt-16 {
    margin-top: 16px;
}

.page-contact-us {
    background: var(--white-color);
}

.fs-25 {
    font-size: 35px;
}

@media (max-width:480px) {
    .page-header {
        background: url(https://ingomulibrary.s3.us-east-1.amazonaws.com/images/ingomu-speakers-mobile.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
}
</style>