<template>
  <div id="app">
    <router-view/>
    <CookieBanner />
  </div>
</template>

<script>
import CookieBanner from './components/CookieBanner.vue';

export default {
  name: 'App',
  components: {
    CookieBanner
  }
}
</script>
<style>
.w-324 {
  width: 324px;
}
.w-415 {
  width: 415px;
}
.w-746 {
  width: 746px;
}
.w-858 {
  width: 858px;
}
.h-425 {
  height: 425px;
}
.h-446 {
  height: 446px;
}
.h-464 {
  height: 464px;
}
.fit-cover {
  object-fit: cover;
}
.blogBody img {
    object-fit: cover !important;
}
.text-royal-blue {
    color: #3b5998 !important;
}
.mt-46 {
  margin-top: 46px;
}
.tab-content .accordion-button::after {
  color: #3b5998 !important;
}
blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: revert !important;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}
</style>