<template>
    <div id="home">
        <!-- Preloader Start -->
        <div class="preloader">
            <div class="loading-container">
                <div class="loading"></div>
                <div id="loading-icon"><img
                        src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/Ingomu-Logo-White.png" alt="">
                </div>
            </div>
        </div>
        <!-- Preloader End -->

        <!-- Header Start -->
        <SiteHeader></SiteHeader>
        <!-- Header End -->

        <!-- Hero Section Start -->
        <div class="hero bg-section parallaxie" id="home">
            <div class="hero-section">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <!-- Hero Content Start -->
                            <div class="hero-content">
                                <!-- Section Title Start -->
                                <div class="section-title">
                                    <h3 class="wow fadeInUp">WELCOME TO THE INGOMU COACHING AND PERSONAL DEVELOPMENT APP</h3>
                                    <h1 class="wow fadeInUp" data-wow-delay="0.2s">Grow, Thrive, and
                                        Succeed<br><span>Anytime, Anywhere</span></h1>
                                </div>
                                <!-- Section Title End -->

                                <!-- Hero Button Start -->
                                <div class="hero-btn wow fadeInUp row col-lg-6" data-wow-delay="0.4s">
                                    <div class="col-md-3 col-6 mb-3">
                                        <a href="https://apps.apple.com/us/app/ingomu/id1621410470" target="_blank">
                                            <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/app_badge.svg"
                                                alt="" class="h-50">
                                        </a>
                                    </div>
                                    <div class="col-md-3 col-6 mb-3">
                                        <a href="https://play.google.com/store/apps/details?id=com.ingomu.ingomu"
                                            target="_blank">
                                            <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/google_badge.png"
                                                alt="" class="h-50">
                                        </a>
                                    </div>
                                    <div class="col-md-4 mb-3 thirditem">
                                        <div class="intro-video-box">
                                            <div class="video-play-button">
                                                <a href="https://www.youtube.com/watch?v=t2E7oKCK48Y"
                                                    class="popup-video" data-cursor-text="Play">
                                                    <i class="fa-solid fa-play"></i>
                                                </a>
                                                <p>Watch Video</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Hero Button End -->
                            </div>
                            <!-- Hero Content End -->
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-12">
                            <div class="export-coaching-box wow fadeInUp" data-wow-delay="0.6s">
                                <!-- Export Coaching Step Start -->
                                <div class="export-coaching-step h-150">
                                    <div class="icon-box">
                                        <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/ai.svg"
                                            alt="">
                                    </div>
                                    <div class="export-coaching-step-content">
                                        <p>AI COACH ASSISTANT<br>Receive personalized guidance instantly with our
                                            AI-powered coach assistant.</p>
                                    </div>
                                </div>
                                <!-- Export Coaching Step End -->

                                <!-- Export Coaching Step Start -->
                                <div class="export-coaching-step h-150">
                                    <div class="icon-box">
                                        <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/livegroup.svg"
                                            alt="">
                                    </div>
                                    <div class="export-coaching-step-content">
                                        <p>LIVE GROUP SESSIONS<br>Engage with expert coaches in real-time during
                                            interactive live virtual sessions.</p>
                                    </div>
                                </div>
                                <!-- Export Coaching Step End -->

                                <!-- Export Coaching Step Start -->
                                <div class="export-coaching-step h-150">
                                    <div class="icon-box">
                                        <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/app.svg"
                                            alt="">
                                    </div>
                                    <div class="export-coaching-step-content">
                                        <p>ON-DEMAND RESOURCES<br>Explore a library of over 1,000 on-demand sessions for
                                            learning at your pace.</p>
                                    </div>
                                </div>
                                <!-- Export Coaching Step End -->

                                <!-- Export Coaching Step Start -->
                                <div class="export-coaching-step h-150">
                                    <div class="icon-box">
                                        <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/messaging.svg"
                                            alt="">
                                    </div>
                                    <div class="export-coaching-step-content">
                                        <p>ONE-ON-ONE MESSAGING<br>Connect directly with coaches through private
                                            messaging for personalized support.</p>
                                    </div>
                                </div>
                                <!-- Export Coaching Step End -->

                                <!-- Export Coaching Step Start -->
                                <div class="export-coaching-step  h-150">
                                    <div class="icon-box">
                                        <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/engagement.svg"
                                            alt="">
                                    </div>
                                    <div class="export-coaching-step-content">
                                        <p>COMMUNITY ENGAGEMENT<br>Inspire and be inspired by sharing insights and
                                            interacting on our vibrant social feed.</p>
                                    </div>
                                </div>
                                <!-- Export Coaching Step End -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Hero Section End -->

        <!-- About Us Start -->
        <div class="our-approach bg-section" id="about">
            <div class="container">
                <div class="row section-row align-items-center">
                    <div class="col-lg-7">
                        <!-- Section Title Start -->
                        <div class="section-title">
                            <h3 class="wow fadeInUp">ABOUT US</h3>
                            <h2 class="wow fadeInUp" data-wow-delay="0.25s">Empowering Growth with <span>Affordable
                                    Coaching for Everyone</span></h2>
                        </div>
                        <!-- Section Title End -->
                    </div>

                    <div class="col-lg-5">
                        <!-- Section Btn Start -->
                        <div class="section-btn wow fadeInUp" data-wow-delay="0.5s">
                            <router-link :to="{ name: 'contact' }" class="btn-default">Contact Us</router-link>
                        </div>
                        <!-- Section Btn End -->
                    </div>
                </div>

                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <!-- Our Approach Content Start -->
                        <div class="our-approach-content">
                            <p class="wow fadeInUp">
                                <b>At Ingomu, we believe everyone deserves a life of growth, balance, and
                                    fulfillment.</b>
                                Our mission is to make professional coaching and personal development accessible and affordable for all. By
                                bridging the gap between expert guidance and everyday life, our app provides the tools,
                                support, and inspiration you need to overcome challenges and thrive each day.

                            </p>

                            <!-- Mission Vision Item Start -->
                            <div class="mission-vision-list">
                                <div class="row">
                                    <div class="col-md-6 col-12">
                                        <!-- Achieve Goals Item Start -->
                                        <div class="mission-vision-item wow fadeInUp" data-wow-delay="0.4s">
                                            <div class="icon-box">
                                                <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/icon-our-mission.svg"
                                                    alt="">
                                            </div>

                                            <div class="mission-vision-content">
                                                <h3>Achieve Goals</h3>
                                                <p>Unlock your potential with personalized coaching and development that keeps you on track.</p>
                                            </div>
                                        </div>
                                        <!-- Achieve Goals Item End -->
                                    </div>

                                    <div class="col-md-6 col-12">
                                        <!-- Reduce Stress Item Start -->
                                        <div class="mission-vision-item wow fadeInUp" data-wow-delay="0.2s">
                                            <div class="icon-box">
                                                <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/icon-our-mission.svg"
                                                    alt="">
                                            </div>

                                            <div class="mission-vision-content">
                                                <h3>Reduce Stress</h3>
                                                <p>Master mindfulness and relaxation techniques to ease tension and
                                                    promote inner calm.</p>
                                            </div>
                                        </div>
                                        <!-- Reduce Stress Item End -->
                                    </div>

                                    <div class="col-md-6 col-12">
                                        <!-- Build Resilience Item Start -->
                                        <div class="mission-vision-item wow fadeInUp" data-wow-delay="0.4s">
                                            <div class="icon-box">
                                                <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/icon-our-mission.svg"
                                                    alt="">
                                            </div>

                                            <div class="mission-vision-content">
                                                <h3>Build Resilience</h3>
                                                <p>Develop the tools and mindset to overcome challenges and thrive under
                                                    pressure.</p>
                                            </div>
                                        </div>
                                        <!-- Build Resilience Item End -->
                                    </div>

                                    <div class="col-md-6 col-12">
                                        <!-- Find Community Item Start -->
                                        <div class="mission-vision-item wow fadeInUp" data-wow-delay="0.4s">
                                            <div class="icon-box">
                                                <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/icon-our-mission.svg"
                                                    alt="">
                                            </div>

                                            <div class="mission-vision-content">
                                                <h3>Find Community</h3>
                                                <p>Join a supportive network of like-minded individuals on similar
                                                    personal growth journeys.</p>
                                            </div>
                                        </div>
                                        <!-- Find Community Item End -->
                                    </div>
                                </div>
                            </div>
                            <!-- Mission Vision Item End -->

                            <!-- Our Approch Content Footer Start -->
                            <!-- <div class="our-approach-content-footer wow fadeInUp btn-app" data-wow-delay="0.8s">
                                Download the app today and start your 7-day free trial.
                                <a href="https://play.google.com/store/apps/details?id=com.ingomu.ingomu"
                                    target="_blank" class="p-0"> <img src="../assets/v2/images/icon/Android_robot.svg"
                                        class="h22 pl-15 pr-15" alt=""></a>
                                <a href="https://apps.apple.com/us/app/ingomu/id1621410470" target="_blank"
                                    class="p-0"><img src="../assets/v2/images/icon/apple-icon.svg" class="h22"
                                        alt=""></a>
                            </div> -->
                            <!-- Our Approch Content Footer End -->
                        </div>
                        <!-- Our Approach Content End -->
                    </div>

                    <div class="col-lg-6">
                        <!-- Our Approach Image Start -->
                        <div class="our-approach-image">
                            <figure class="image-anime reveal">
                                <!-- <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/ingomu-mobile-coaching-app.png"
                                    alt=""> -->
                                    <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/ingomu-coaching-empowers-growth_1.png"
                                    alt="">
                            </figure>
                        </div>
                        <!-- Our Approach Image End -->
                    </div>
                </div>
            </div>
        </div>
        <!-- About Us End -->

        <!-- Free Trial Section Start -->
        <div class="company-growth bg-section" id="freetrial">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-5">
                        <!-- Company Growth Image Start -->
                        <div class="company-growth-image">
                            <figure class="image-anime reveal d-none d-sm-block">
                                <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/ingomu-coaching-app.png"
                                    alt="">
                            </figure>
                        </div>
                        <!-- Company Growth Image End -->
                    </div>

                    <div class="col-lg-7">
                        <!-- Company Growth Content Start -->
                        <div class="company-growth-content">
                            <!-- Section Title Start -->
                            <div class="section-title">
                                <h3 class="wow fadeInUp">FREE TRIAL</h3>
                                <h2 class="wow fadeInUp" data-wow-delay="0.25s">Your Goals, Your Journey <span>Try It Free
                                        for 7 Days!</span></h2>
                                <p class="wow fadeInUp" data-wow-delay="0.5s"><b>Experience the benefits of holistic
                                        coaching and personal development risk-free.</b> Enjoy live virtual group sessions, on-demand resources,
                                    the AI Coach Assistant Eric, and a supportive community—free for seven days with no
                                    commitment required.</p>
                            </div>
                            <!-- Section Title End -->
                            <div class="col-lg-20 row wow fadeInUp" data-wow-delay="0.4s">
                                <div class="col-md-3 col-6 mb-3">
                                    <a href="https://apps.apple.com/us/app/ingomu/id1621410470" target="_blank">
                                        <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/app_badge.svg"
                                            alt="" class="h-50">
                                    </a>
                                </div>
                                <div class="col-md-3 col-6 mb-3">
                                    <a href="https://play.google.com/store/apps/details?id=com.ingomu.ingomu"
                                        target="_blank">
                                        <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/google_badge.png"
                                            alt="" class="h-50">
                                    </a>
                                </div>
                                <div class="col-md-6 mb-3 thirditem">
                                    <div class="intro-video-box">
                                        <div class="video-play-button">
                                            <a href="https://www.youtube.com/watch?v=CkVA6baU5eo" class="popup-video"
                                                data-cursor-text="Play">
                                                <i class="fa-solid fa-play"></i>
                                            </a>
                                            <p class="text-black">See How It Works</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <!-- Company Growth Content End -->
                    </div>

                    <div class="col-lg-12">
                        <!-- Company Growth Box Start -->
                        <div class="company-growth-box">
                            <!-- Company Growth Item Start -->
                            <!-- <div class="company-growth-item">
                                <h2><span class="counter">30</span>+</h2>
                                <h3>Coaching Communities</h3>
                                <p>Unlimited access to 30+ expert-led coaching communities specializing in life,
                                    wellness, career, and soul transformation.</p>
                            </div> -->
                            <div class="company-growth-item">
                                <h2><span class="counter">30</span>+</h2>
                                <h3>Coaching Communities</h3>
                                <p class="h-120">Unlimited access to 30+ expert-led coaching communities specializing in
                                    life,
                                    wellness, career, and soul transformation.</p>
                            </div>
                            <!-- Company Growth Item End -->

                            <!-- Company Growth Item Start -->
                            <div class="company-growth-item">
                                <h2><span class="counter">60</span>+</h2>
                                <h3>Live Coaching Sessions</h3>
                                <p class="h-120">Participate in 60+ live virtual group coaching sessions every month to
                                    unlock
                                    personal and professional growth.<br></p>
                            </div>
                            <!-- Company Growth Item End -->

                            <!-- Company Growth Item Start -->
                            <div class="company-growth-item">
                                <h2><span class="counter">1,000</span>+</h2>
                                <h3>On-Demand Resources</h3>
                                <p class="h-120">Enjoy 24/7 access to 1,000+ on-demand coaching sessions for immediate
                                    guidance and
                                    support.</p>
                            </div>
                            <!-- Company Growth Item End -->

                            <!-- Company Growth Item Start -->
                            <div class="company-growth-item">
                                <h2><span class="counter">70</span>%</h2>
                                <h3>Increase in Well-Being</h3>
                                <p class="h-120">Experience a proven transformation—users self report an average 70%
                                    boost in
                                    well-being after just one live session.</p>
                            </div>
                            <!-- Company Growth Item End -->
                        </div>
                        <!-- Company Growth Box End -->
                    </div>
                </div>
            </div>
        </div>
        <!-- Free Trial Section End -->

        <!-- Our Pricing Section Start -->
        <div class="our-pricing bg-section" id="pricing">
            <div class="container">
                <div class="row">
                    <div class="col-lg-5">
                        <!-- Pricing Content Start -->
                        <div class="pricing-content">
                            <!-- Section Title Start -->
                            <div class="section-title">
                                <h3 class="wow fadeInUp">pricing</h3>
                                <h2 class="wow fadeInUp" data-wow-delay="0.25s">Find the Right Plan <span>for Your
                                        Journey</span></h2>
                                <p class="wow fadeInUp" data-wow-delay="0.5s"><b>Start your journey with a 7-day free
                                        trial and unlock full access!</b>
                                    After your trial, continue for only $29 per month, or save big with an annual
                                    subscription for just $290—get two months free!
                                </p>
                            </div>
                            <!-- Section Title End -->

                            <!-- Pricing Btn Start -->
                            <div class="col-lg-12 row">
                                <div class="col-md-6 col-6 mb-3">
                                    <a href="https://apps.apple.com/us/app/ingomu/id1621410470" target="_blank">
                                        <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/app_badge.svg"
                                            alt="" class="h-50">
                                    </a>
                                </div>
                                <div class="col-md-6 col-6 mb-3">
                                    <a href="https://play.google.com/store/apps/details?id=com.ingomu.ingomu"
                                        target="_blank">
                                        <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/google_badge.png"
                                            alt="" class="h-50">
                                    </a>
                                </div>
                            </div>

                            <!-- Pricing Btn End -->
                        </div>
                        <!-- Pricing Content End -->
                    </div>

                    <div class="col-lg-7">
                        <!-- Pricing Box List Start -->
                        <div class="pricing-box-list">
                            <!-- Pricing Box Start -->
                            <div class="pricing-box wow fadeInUp">
                                <!-- Pricing Box Content Start -->
                                <div class="pricing-box-content">
                                    <!-- Pricing Title Start -->
                                    <div class="pricing-title">
                                        <p>Monthly</p>
                                        <h2><sup>$</sup>29<sub>/month</sub></h2>
                                    </div>
                                    <!-- Pricing Title End -->
                                </div>
                                <!-- Pricing Box Content End -->

                                <!-- Pricing List Start -->
                                <div class="pricing-list">
                                    <ul>
                                        <li>AI Coach Assistant</li>
                                        <li>Live Group Coaching</li>
                                        <li>Unlimited Access</li>
                                        <li>7-Day Free Trial</li>
                                        <li>Coach Messaging</li>
                                        <li>Cancel Anytime</li>
                                    </ul>
                                </div>
                                <!-- Pricing List End -->
                            </div>
                            <!-- Pricing Box End -->

                            <!-- Pricing Box Start -->
                            <div class="pricing-box highlighted-box wow fadeInUp" data-wow-delay="0.2s">
                                <!-- Pricing Box Content Start -->
                                <div class="pricing-box-content">
                                    <!-- Pricing Title Start -->
                                    <div class="pricing-title">
                                        <p>Annually</p>
                                        <h2><sup>$</sup>290<sub>/year</sub></h2>
                                    </div>
                                    <!-- Pricing Title End -->
                                </div>
                                <!-- Pricing Box Content End -->

                                <!-- Pricing List Start -->
                                <div class="pricing-list">
                                    <ul>
                                        <li>AI Coach Assistant</li>
                                        <li>Live Group Coaching</li>
                                        <li>Unlimited Access</li>
                                        <li>Coach Messaging</li>
                                        <li>2 Months Free</li>
                                        <li>7-Day Free Trial</li>
                                    </ul>
                                </div>
                                <!-- Pricing List End -->
                            </div>
                            <!-- Pricing Box End -->
                        </div>
                        <!-- Pricing Box List Start -->

                        <!-- Pricing Benifit List Start -->
                        <div class="pricing-benefit-list wow fadeInUp" data-wow-delay="0.6s">
                            <ul>
                                <li><img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/icon-pricing-benefit-1.svg"
                                        alt="">Get a 7-day free trial</li>
                                <li><img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/icon-pricing-benefit-2.svg"
                                        alt="">No hidden fees</li>
                                <li><img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/icon-pricing-benefit-3.svg"
                                        alt="">Cancel anytime</li>
                            </ul>
                        </div>
                        <!-- Pricing Benifit List End -->
                    </div>
                </div>
            </div>
        </div>
        <!-- Our Pricing Section End -->

        <!-- Our Testimonial Section Start -->
        <div class="our-testimonial bg-section" id="testimonials">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-8">
                        <div class="our-testimonial-content">
                            <!-- Section Title Start -->
                            <div class="section-title">
                                <h3 class="wow fadeInUp">Testimonials</h3>
                                <h2 class="wow fadeInUp" data-wow-delay="0.25s">Success Stories
                                    <span>from Our Users</span>
                                </h2>
                            </div>
                            <!-- Section Title End -->
                        </div>
                    </div>

                    <div class="col-lg-8">
                        <!-- Testimonial Slider Start -->
                        <div class="testimonial-slider">
                            <div class="swiper">
                                <div class="swiper-wrapper" data-cursor-text="Drag">
                                    <!-- Testimonial Slide Start -->
                                    <div class="swiper-slide" v-for="(testimony, index) in testimonialsList"
                                        :key="index">
                                        <div class="testimonial-item">
                                            <!-- <div class="testimonial-slider-image">
                                                <figure class="image-anime">
                                                    <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/Ingomu_Logo.png" alt="">
                                                </figure>
                                            </div> -->

                                            <div class="testimonial-slider-content">
                                                <div class="testimonial-header">
                                                    <div class="testimonial-rating-company">
                                                        <img src="images/icon-testimonial-rating-4.svg" alt="">
                                                    </div>
                                                    <div class="testimonial-quote">
                                                        <img src="images/testimonial-quote.svg" alt="">
                                                    </div>
                                                </div>
                                                <div class="testimonial-rating">
                                                    <i class="fa-solid fa-star"></i>
                                                    <i class="fa-solid fa-star"></i>
                                                    <i class="fa-solid fa-star"></i>
                                                    <i class="fa-solid fa-star"></i>
                                                    <i class="fa-solid fa-star"></i>
                                                </div>
                                                <div class="testimonial-content">
                                                    <p>{{ testimony.comment }}</p>
                                                </div>
                                                <div class="author-content">
                                                    <h3>{{ testimony.name }}</h3>
                                                    <p>{{ testimony.title }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Testimonial Slide End -->
                                </div>
                                <div class="testimonial-btn">
                                    <div class="testimonial-button-prev"></div>
                                    <div class="testimonial-button-next"></div>
                                </div>
                            </div>
                        </div>
                        <!-- Testimonial Slider End -->
                    </div>

                </div>
            </div>
        </div>
        <!-- Our Testimonial Section End -->

        <BlogSection />

        <!-- Our FAQs Section Start -->
        <div class="our-faqs bg-section mt-100" id="faq">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <!-- Our FAQs Content Start -->
                        <div class="our-faqs-content">
                            <!-- Section Title Start -->
                            <div class="section-title">
                                <h3 class="wow fadeInUp">FAQ</h3>
                                <h2 class="wow fadeInUp" data-wow-delay="0.25s">Answers to<br><span>Your
                                        Questions</span></h2>
                            </div>
                            <!-- Section Title End -->

                            <!-- FAQs CTA Box Start -->
                            <div class="faqs-cta-box wow fadeInUp" data-wow-delay="0.75s">
                                <div class="faqs-cta-content">
                                    <h3>Do you still have questions?</h3>
                                    <p>We're ready to help!</p>
                                    <router-link :to="{ name: 'contact' }" class="btn-default">Contact Us</router-link>
                                </div>
                            </div>
                            <!-- FAQs CTA Box End -->
                        </div>
                        <!-- Our FAQs Content End -->
                    </div>

                    <div class="col-lg-6">
                        <div class="our-faq-section">
                            <!-- FAQ Accordion Start -->
                            <div class="faq-accordion" id="faqaccordion">
                                <!-- FAQ Item Start -->
                                <div class="accordion-item wow fadeInUp" v-for="(faq, index) in faqGeneral"
                                    :key="index">
                                    <h2 class="accordion-header" :id="faq.id">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" :data-bs-target="faq.hash" aria-expanded="true"
                                            :aria-controls="faq.controls">
                                            {{ faq.question }}
                                        </button>
                                    </h2>
                                    <div :id="faq.controls" class="accordion-collapse collapse"
                                        :aria-labelledby="faq.id" data-bs-parent="#faqaccordion">
                                        <div class="accordion-body">
                                            <p v-html="faq.answer"></p>
                                        </div>
                                    </div>
                                </div>
                                <!-- FAQ Item End -->
                            </div>
                            <!-- FAQ Accordion End -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Our FAQs Section End -->

        <!-- Page Book Appointment Start -->
        <div class="page-book-appointment">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-5">
                        <!-- Book Appointment Content Start -->
                        <div class="book-appointment-content">
                            <!-- Section Title Start -->
                            <div class="section-title">
                                <h3 class="wow fadeInUp" data-wow-delay="0.2s">Weekly tips in your inbox</h3>
                                <h2 class="wow fadeInUp">ACTIONABLE TIPS<br><span>FOR A BETTER LIFE</span></h2>
                            </div>
                            <!-- Section Title Start -->
                        </div>
                        <!-- Book Appointment Content End -->
                    </div>

                    <div class="col-lg-7">
                        <!-- Book Appointment Form Start -->
                        <div class="appointment-form">
                            <p class="wow fadeInUp">By subscribing to our newsletter, you allow us to contact you at the
                                email address provided. You may unsubscribe at any time. Your information will never be
                                shared or sold.</p>

                            <!-- Appointment Form Start -->
                            <form id="newsletterForm" action="#" method="POST" data-toggle="validator"
                                class="wow fadeInUp" data-wow-delay="0.25s">
                                <div class="row">
                                    <div class="form-group col-md-12 mb-4">
                                        <input type="email" name="email" class="form-control" id="email"
                                            placeholder="Enter your email" required>
                                        <div class="help-block with-errors"></div>
                                    </div>

                                    <div class="col-md-12">
                                        <button type="submit" class="btn-default mb-25">Subscribe</button>
                                        <div id="msgSubmit" class="h3 hidden"></div>
                                    </div>
                                </div>
                            </form>
                            <!-- Appointment Form End -->
                        </div>
                        <!-- Book Appointment Form End -->
                    </div>
                </div>
            </div>
        </div>
        <!-- Page Book Appointment End -->

        <!-- Newsletter modal Start -->
        <Newsletter />
         <!-- Newsletter modal End -->

        <!-- Footer Start -->
        <SiteFooter></SiteFooter>
        <!-- Footer End -->
    </div>
</template>

<script>
import SiteHeader from "@/components/InfineHeader";
import SiteFooter from "@/components/InfineFooter";
import BlogSection from "@/components/BlogSection";
import $ from "jquery";
import 'magnific-popup';
import postscribe from "postscribe";
import { mapActions, mapGetters } from "vuex";
import { slicknav } from '../mixins/slicknav';
import { parrallaxie } from "../mixins/parallaxie";
import { counterup } from "../mixins/counterup";
import { reveal } from "../mixins/reveal";
import { testimonal } from "../mixins/testimonial";
import { validator } from "../mixins/validator";
import Newsletter from "../components/Newsletter.vue";
import posthog from 'posthog-js';
import Swal from "sweetalert2";

const TIMEOUT = 1000;

export default {
    name: "home",
    metaInfo() {
        return {
            title: "Achieve Growth and Balance with Expert Coaching | Ingomu App",
        }
    },
    components: {
        SiteHeader,
        SiteFooter,
        Newsletter,
        BlogSection
    },
    data() {
        return {
            newsletterForm: {
                email: ""
            },
            testimonialsList: [
                {
                    name: "Aiden",
                    title: "Ingomu Subscriber",
                    comment: "I've been going through a tough time at work and it's been spilling over into my home life. I started using the app a while back and I've found so much support in the communities. They've really helped me come up with strategies to tackle the challenges, both at work and at home. They’ve boosted my confidence and helped me improve my relationships with my colleagues and family. I'm thankful for the impact this app has had on my life and career."
                },
                {
                    name: "Anastasia S.",
                    title: "Ingomu Subscriber",
                    comment: "Where else can you get affordable access to unlimited coaching when you need it? I’ve been able to control my anxiety by joining live sessions. I’m less stressed and more focused! Thanks."
                },
                {
                    name: "Caitlin",
                    title: "Ingomu Subscriber",
                    comment: "I'm a full-time working mom of two and let me tell you, life has been INSANE lately. Work stress, the kids, trying to keep it all together...it was seriously making me lose my mind! I was feeling so overwhelmed and anxious, I didn't think I could handle it anymore. Then I heard about this Ingomu coaching app and I was like, \"Why not?\" Worst case scenario, it's a waste of time, right? But OMG, it's been a total game-changer! The sessions have helped me so much with managing stress, finding balance, and actually being present for my family and work. I'm not gonna lie, it's kinda amazing how much better I feel. If you're struggling like I was, this app is 100% worth it."
                },
                {
                    name: "Chris W.",
                    title: "Ingomu Subscriber",
                    comment: "Life can be difficult to navigate for everyone. Life coaching is tremendously helpful for overcoming the challenges we all face, but historically it’s only been affordable for the well-off. Ingomu changes the game. It brings amazing coaches, covering a broad range of life, work, health, and spiritual topics, to the masses, at an incredibly reasonable price."
                }
            ],
            faqGeneral: [
                {
                    id: "headingOne",
                    controls: "collapseOne",
                    hash: "#collapseOne",
                    question: "Who will be my coaches?",
                    answer: "You’ll have unlimited access to a diverse group of vetted life, work, wellness, and soul coaches. Many are certified in their areas of expertise."
                },
                {
                    id: "headingTwo",
                    controls: "collapseTwo",
                    hash: "#collapseTwo",
                    question: "What types of topics do coaches cover?",
                    answer: "Coaches specialize in areas like personal development, leadership, health and wellness, career growth, and mindfulness. There are over 30 different coaches who can help you with the support you need when you need it."
                },
                {
                    id: "headingThree",
                    controls: "collapseThree",
                    hash: "#collapseThree",
                    question: "How can I access Ingomu?",
                    answer: "Ingomu is available via our app for iOS and Android, which can be downloaded from the <a href=\"https://apps.apple.com/us/app/ingomu/id1621410470\" target=\"_blank\">Apple App Store<\/a> or <a href=\"https://play.google.com/store/apps/details?id=com.ingomu.ingomu\" target=\"_blank\">Google Play<\/a>."
                },
                {
                    id: "headingFour",
                    controls: "collapseFour",
                    hash: "#collapseFour",
                    question: "How do the app’s features work?",
                    answer: "We’ve created a helpful guide with short videos to help you get started. Find it <a href=\"https://ingomu.com/welcome\">here.<\/a>"
                },
                {
                    id: "headingFive",
                    controls: "collapseFive",
                    hash: "#collapseFive",
                    question: "What is included in my subscription?",
                    answer: "Your subscription provides unlimited access to live virtual group coaching sessions, more than 1,000 on-demand recorded sessions, one-on-one messaging with coaches, engagement in discussions between sessions, and AI coaching assistant, Eric. You can get the support you need, whenever you need it."
                },
                {
                    id: "headingSix",
                    controls: "collapseSix",
                    hash: "#collapseSix",
                    question: "How are subscriptions priced?",
                    answer: "Subscriptions are $29/month after a 7-day free trial. An annual plan is $290. For businesses, schools, or organizations, contact us for volume pricing."
                },
                {
                    id: "headingSeven",
                    controls: "collapseSeven",
                    hash: "#collapseSeven",
                    question: "Can I try the app before signing up?",
                    answer: "Yes! Enjoy a 7-day free trial with full access. Download the app for <a href=\"https://apps.apple.com/us/app/ingomu/id1621410470\" target=\"_blank\">iOS<\/a> or <a href=\"https://play.google.com/store/apps/details?id=com.ingomu.ingomu\" target=\"_blank\">Android<\/a> to get started."
                },
                {
                    id: "headingEight",
                    controls: "collapseEight",
                    hash: "#collapseEight",
                    question: "How do I cancel my subscription?",
                    answer: "Cancel anytime through your device’s App Store or Play Store. Ensure cancellation occurs at least 24 hours before your renewal date to avoid charges."
                },
                {
                    id: "headingNine",
                    controls: "collapseNine",
                    hash: "#collapseNine",
                    question: "How do I delete my account and data?",
                    answer: "In the app, go to the Account menu and select “Delete Account” under My Account. Your account and data will be removed immediately."
                },
            ]
        }
    },
    methods: {
        ...mapActions(["newsletterConsumer"]),
        submit(email) {
            var _this = this;
            _this
                .newsletterConsumer({
                    email: email
                })
        },
        strippedHtml(htmlText) {
            return htmlText.replace(/<[^>]+>/g, '').split(' ').slice(0, 25).join(" ")
        },
        imageLink(url) {
            // return unescape(url.substring(0, url.indexOf("&token=")))
            return unescape(url.substring(0, url.indexOf("&token=")))
        },
        scrollTo(hashtag) {
            // setTimeout(() => { document.getElementById(hashtag.slice(1)).scrollIntoView() }, TIMEOUT)
            setTimeout(() => {
                const element = document.getElementById(hashtag.slice(1)); // Your target element
                const headerOffset = 150;
                const elementPosition = element.getBoundingClientRect().top;
                const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                });
            }, TIMEOUT)
        },
    },
    mounted() {
        if (this.$route.hash) {
            setTimeout(() => this.scrollTo(this.$route.hash), TIMEOUT);
        }
        postscribe(
            "#home",
            `<script src="https://ingomulibrary.s3.us-east-1.amazonaws.com/js/jquery.magnific-popup.min.js"><\/script>`
        );
        posthog.capture('$pageview')

        var $window = $(window);
        var $this = this;


        // /* Preloader Effect */
        // $window.on('load', function () {
        //     $(".preloader").fadeOut(600);
        // });

        $(function () {
            /* Preloader Effect */
            setTimeout(function () {
                $(".preloader").fadeOut(600);
            }, 600);

            $('.popup-video').magnificPopup({
                type: 'iframe',
                mainClass: 'mfp-fade',
                removalDelay: 160,
                preloader: false,
                fixedContentPos: true
            });

            /* Animated Wow Js */
            new WOW().init();

            // Init Counter 
            counterup()

            // Parallax
            parrallaxie()

            // Reveal
            reveal()

            //Slick Menu JS
            slicknav()

            // Testimonial 
            testimonal()

            validator((newsletterForm) => {
                let email = newsletterForm[0]["value"];
                posthog.capture('home_newsletter', {
                    'email': this.email
                });
                $this.submit(email);
                Swal.fire({
                        title: "",
                        text: "Thank you for subscribing. Welcome to our community! Look for your first coaching tips shortly.",
                        icon: "success",
                        confirmButtonClass: "btn btn-secondary",
                        heightAuto: true,
                    });
            })
        })
    },
    beforeDestroy() {
        posthog.capture('$pageleave')
    }
}
</script>
<style scoped>
.hero {
    background: url(https://ingomulibrary.s3.us-east-1.amazonaws.com/images/ingomu-coaching-30.png);
}

.h-50 {
    height: 50px !important;
}

.ml-40 {
    margin-left: 40px;
}

.intro-video-box {
    display: inline;
}

.video-play-button {
    display: inline-flex;
}

.h-150 {
    height: 150px;
}

.our-approach,
.our-pricing {
    background: none;
}

.our-faqs {
    background: var(--white-color);
    padding: 100px 0;
}

.h22 {
    height: 22px;
}

.mx-200 {
    max-width: 200px;
}

.mx-300 {
    max-width: 300px;
}

.h-200 {
    height: 200px;
}

.mw-100 {
    max-width: 100px !important;
}

.mh-100 {
    max-height: 100px !important;
}

.mw-200 {
    max-width: 200px !important;
}

.mh-200 {
    max-height: 200px !important;
}

.btn-app {
    position: relative;
    display: inline-block;
    background-image: linear-gradient(to right, var(--dark-color) 0%, var(--accent-color) 50%, var(--dark-color) 100%);
    background-size: 200% auto;
    border-radius: 100px;
    color: var(--white-color);
    font-size: 16px;
    font-weight: 700;
    line-height: 1em;
    text-transform: capitalize;
    border: none;
    padding: 17px 20px;
    transition: all 0.4s ease-in-out;
    overflow: hidden;
    z-index: 0;
}

.pl-15 {
    padding-left: 15px;
}

.pr-15 {
    padding-right: 15px;
}

.p-0 {
    padding: 0;
}

.mb-60 {
    margin-bottom: 60px;
}

.mb-25 {
    margin-bottom: 25px;
}

.testimonial-slider-content,
.testimonial-slider-image {
    width: 100%;
}

.blog-item .blog-featured-image a {
    cursor: pointer;
}

.h-120 {
    height: 120px;
}

.testimonial-rating-item .icon-box { 
    border-bottom: none;
}

@media (max-width:991px) {
    .hero {
        background: url(https://ingomulibrary.s3.us-east-1.amazonaws.com/images/ingomu-coaching-mobile.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .export-coaching-box {
        margin-bottom: 100px;
    }
}

@media (max-width:480px) {
    .thirditem {
        margin-top: 20px;
    }
}
</style>