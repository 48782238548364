// components/CookieBanner.vue
<template>
  <div class="banner" v-if="!cookieConsent">
    <p>
        We use cookies to enhance your experience, analyze site traffic, and personalize content.<br/>By continuing to use our website, you agree to our use of cookies.
    </p>
    <button type="button" class="btn-default mr-20" @click="acceptCookies">Accept cookies</button>
    <button type="button" class="btn-default" @click="rejectCookies">Decline cookies</button>
  </div>
</template>

<script>
import posthog from 'posthog-js';

export default {
  name: "CookieBanner",
  data() {
    return {
      cookieConsent: localStorage.getItem('cookieConsent') === 'true'
    };
  },
  methods: {
    acceptCookies() {
      localStorage.setItem('cookieConsent', 'true');
      this.cookieConsent = true;
      posthog.opt_in_capturing(); // Enable PostHog tracking
    },
    rejectCookies() {
      localStorage.setItem('cookieConsent', 'true');
      this.cookieConsent = true;
      posthog.opt_out_capturing(); // Disable PostHog tracking
    }
  }
};
</script>

<style scoped>
.banner {
  background-color: #042A2D;
  color: #F2F1ED;
  padding: 20px;
  text-align: center;
  position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 100;
}
.mr-20 {
    margin-right: 20px;
}
</style>