<template>
    <div>
        <!-- Triggered Modal -->
        <div v-if="showModal && !ingomuNewsletter" class="nmodal" @click.self="closeModal">
            <div class="nmodal-content">
                <span class="close" @click="closeModal">&times;</span>
                <img src="https://ingomulibrary.s3.us-east-1.amazonaws.com/images/ingomu-newsletter-user.png"
                    class="br-20" alt="Popup Image" />

                <!-- Brief Text -->
                <p class="modal-text">
                    Stay Inspired – Subscribe to Our Newsletter for Weekly Tips & Motivation!
                </p>

                <!-- Email Form -->

                <form @submit.prevent="handleSubmit">
                    <input type="email" title="email" v-model="email" placeholder="Enter your email" required
                        class="email-input" />
                    <button type="submit" class="btn-default mt-20" title="Subscribe">Subscribe</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import posthog from 'posthog-js';

export default {
    name: 'newsletterpopup',
    data() {
        return {
            email: "",
            showModal: false,
            imageSrc: "https://ingomulibrary.s3.us-east-1.amazonaws.com/images/ingomu-newsletter-user.png",
            ingomuNewsletter: localStorage.getItem('ingomuNewsletter') === 'true'
        };
    },
    beforeMount() {
        const storedDate = localStorage.getItem('ingomunewsdate');
        if (storedDate) {
            const currentDate = Date.now();
            const elapsedDays = (currentDate - parseInt(storedDate, 10)) / (1000 * 60 * 60 * 24); // Convert milliseconds to days
            if (elapsedDays > 14) {
                localStorage.removeItem('ingomunewsdate');
                localStorage.removeItem('ingomuNewsletter');
            } 
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        ...mapActions(["newsletterHomeModal"]),
        submit(email) {
            var _this = this;
            _this
                .newsletterHomeModal({
                    email: email
                })
        },
        handleScroll() {
            const scrollPosition = window.scrollY + window.innerHeight;
            const pageHeight = document.documentElement.scrollHeight;
            const scrollPercentage = (scrollPosition / pageHeight) * 100;

            if (scrollPercentage > 50) { // Trigger at 50% scroll
                this.showModal = true;
                window.removeEventListener('scroll', this.handleScroll); // Remove listener after triggering
            }
        },
        closeModal() {
            this.showModal = false;
            localStorage.setItem('ingomuNewsletter', 'true');
            localStorage.setItem('ingomunewsdate', Date.now());
        },
        handleSubmit() {
            if (this.email && this.email != null && this.validateEmail(email)) {
                this.submit(this.email);
                this.closeModal();
                this.email = ""
                posthog.capture('newsletter_signup', {
                    'date': new Date().toString(),
                    'email': this.email,
                    'type': 'popup'
                })
                this.$alert("Thank you for subscribing. Welcome to our community! Look for your first coaching tips shortly.")
            } else {
                this.$alert("Please enter a valid email.")
            }
        },
        validateEmail(email) {
            const emailRegex = /^[^@]+@\w+(\.\w+)+\w$/; // Adjust as needed
            return emailRegex.test(this.email);
        }
    }
};
</script>

<style scoped>
.nmodal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.nmodal-content {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 300px;
}

.close {
    position: absolute;
    top: 0px;
    right: 5px;
    /* Position the close button inside the modal content */
    font-size: 24px;
    cursor: pointer;
}

.modal-text {
    margin: 20px 0 10px;
    font-size: 16px;
    color: #333;
}

.email-input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

.submit-button {
    width: 100%;
    padding: 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.submit-button:hover {
    background-color: #0056b3;
}

.mt-20 {
    margin-top: 20px;
}

.br-20 {
    border-radius: 20px;
}
</style>
